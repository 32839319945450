:root {
  --background-color: white;
  --text-color: black;
}

.dark-mode {
  --background-color: #2a2e37;
  --text-color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, Playfair Display;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace, Playfair Display;
}

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital@1&display=swap');