.age-verification-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .age-verification-form h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .age-verification-form label {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  .age-verification-form input[type="text"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .age-verification-form .error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .age-verification-form button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .age-verification-form button:hover {
    background-color: #0069d9;
  }
  